.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.flex-container {
	margin: 0 10px;
}
.flex-container .inline {
	width: 80px !important;
	margin: 9px 9px 9px 0;
}
.flex-container .small {
	height: 20px !important;
	line-height: 20px !important;
}
.sub-title {
	color: #888;
	font-size: 14px;
	padding: 30px 0 18px 0;
}
